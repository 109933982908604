import React, { useState, useEffect } from 'react';
import Lola from "../assets/Lola.jpeg";
import Lili from '../assets/Lili.jpeg';
import Netti from '../assets/Netti.jpeg';
import Niki from '../assets/Niki.jpeg';
import Vilma from '../assets/Vilma.jpeg';
import Flóra from '../assets/Flóra.jpeg';
import Lilla from '../assets/Lilla.jpeg';
import { FaFacebook, FaInstagram, FaArrowRight, FaTimes } from "react-icons/fa";

const Teachers = ({ data }) => {
  const teachers = [
    { name: 'Lola', image: Lola, bio: data.teachers1, insta: 'https://instagram.com/lakatosloretta_lola?igshid=YmMyMTA2M2Y=', fb: 'https://www.facebook.com/loretta.lakatos.3' },
    { name: 'Lili', image: Lili, bio: data.teachers2, insta: 'https://instagram.com/lilisziklai?igshid=YmMyMTA2M2Y=', fb: 'https://www.facebook.com/lili.sziklai' },
    { name: 'Vilma', image: Vilma, bio: data.teachers3, insta: 'https://instagram.com/vilmagera?igshid=YmMyMTA2M2Y=', fb: 'https://www.facebook.com/vilma.l.gera' },
    { name: 'Niki', image: Niki, bio: data.teachers4, insta: 'https://instagram.com/lehelvarinikii?igshid=YmMyMTA2M2Y=', fb: 'https://www.facebook.com/nikolett.lehelvari' },
    { name: 'Netti', image: Netti, bio: data.teachers5, insta: 'https://instagram.com/netti_eross?igshid=YmMyMTA2M2Y=', fb: 'https://www.facebook.com/netti.eross' },
    { name: 'Flóra', image: Flóra, bio: data.teachers6, insta: 'https://www.instagram.com/_tothflora_/?igsh=ZnM2a3c0N2xxYXA1', fb: 'https://www.facebook.com/flora.toth.16' },
    { name: 'Lilla', image: Lilla, bio: data.teachers7, insta: 'https://www.instagram.com/lillaapatakii', fb: 'https://www.facebook.com/lilla.pataki.54' },
  ];

  const [index, setIndex] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedTeacher, setSelectedTeacher] = useState(null);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % teachers.length);
    }, 5000);
    return () => clearInterval(interval);
  }, [teachers.length]);

  useEffect(() => {
    if (modalOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [modalOpen]);

  return (
    <div className="bg-black relative w-full h-[800px] flex flex-col items-center overflow-hidden">
    <h1 className='text-5xl text-white teachers-heading'>Tanárok</h1>
    <div className="relative w-full h-full flex justify-center items-center">
      
        {teachers.map((teacher, i) => {
          const offset = (i - index + teachers.length) % teachers.length;
          const scale = offset === 0 ? 'scale-100' : 'scale-75';
          const opacity = offset === 0 ? 'opacity-100' : 'opacity-50';
          const zIndex = offset === 0 ? 'z-10' : 'z-0';
          const translateX = offset === 0 ? 'translate-x-0' : offset === 1 ? 'translate-x-20' : '-translate-x-20';

          return (
            <div
              key={teacher.name}
              className={`absolute transition-all duration-500 ease-in-out ${translateX} ${scale} ${opacity} ${zIndex}`}
            >
              <img src={teacher.image} alt={teacher.name} className="w-80 h-92 object-cover rounded-lg shadow-lg" />
              <div className="absolute bottom-0 bg-black bg-opacity-50 w-full text-white p-4 rounded-b-lg">
                <h1 className="text-lg font-semibold">{teacher.name}</h1>
                <div className="flex justify-between items-center mt-2">
                  <div className="flex gap-4">
                    <a href={teacher.insta}><FaInstagram size={30} /></a>
                    <a href={teacher.fb}><FaFacebook size={30} /></a>
                  </div>
                  <FaArrowRight size={30} className="cursor-pointer" onClick={() => { setSelectedTeacher(teacher); setModalOpen(true); }} />
                </div>
              </div>
            </div>
          );
        })}
      </div>
      {modalOpen && (
        <div className="fixed z-10 inset-0 bg-black bg-opacity-70 flex justify-center items-center" onClick={() => setModalOpen(false)}>
          <div className="bg-white p-6 rounded-lg max-w-lg w-full max-h-[80vh] overflow-y-auto relative" onClick={(e) => e.stopPropagation()}>
            <FaTimes size={24} className="absolute top-4 right-4 cursor-pointer" onClick={() => setModalOpen(false)} />
            <h1 className="text-xl font-bold mb-4">{selectedTeacher.name}</h1>
            <p className="mb-4">{selectedTeacher.bio}</p>
            <div className="flex gap-4 mt-4">
              <a href={selectedTeacher.insta}><FaInstagram size={30} /></a>
              <a href={selectedTeacher.fb}><FaFacebook size={30} /></a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Teachers;
